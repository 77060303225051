import { TrustPilotRating, TrustpilotStyle } from '@finn/ua-vehicle';

const TestimonialSlider = () => {
  return (
    <div className="mb-4 mt-8">
      <TrustPilotRating
        style={TrustpilotStyle.Testimonial}
        mobileClassName="-left-10"
        width="420px"
        mobileWidth="250px"
        mobileHeight="42px"
        desktopHeight="42px"
      />
    </div>
  );
};

export default TestimonialSlider;
